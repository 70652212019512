import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import PrimaryBox from 'components/Advanced_User/All_OpenHAB_Tutorials/PrimaryBox';
import MqttTimeLine from 'components/Products/CommentList/MqttUpdatesList';
import MqttIftttTimeLine from 'components/Products/CommentList/MqttIFTTTList';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='OpenHAB Articles for Developers' dateChanged='2021-05-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='Adding your INSTAR IP Camera to your OpenHAB Home Automation System.' image='/images/Search/AU_SearchThumb_OpenHAB.png' twitter='/images/Search/AU_SearchThumb_OpenHAB.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Alle_OpenHAB_Tutorials/' locationFR='/fr/Advanced_User/All_OpenHAB_Tutorials/' crumbLabel="OpenHAB" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <MqttTimeLine mdxType="MqttTimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <MqttIftttTimeLine mdxType="MqttIftttTimeLine" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      